import styles from './artikeldaten.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Dropdown from '@wienenergiegithub/ui-next/src/common/components/Dropdown/Dropdown';

import {
  unstable_useFormState as useFormState,
  unstable_Form as ReakitForm,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import Checkbox from '@wienenergiegithub/ui-next/src/common/components/Checkbox/Checkbox';

const Form = ({ form, categories }) => {
  return (
    <div className={styles.form}>
      <InputField name="asn" label="ASN" {...form} />
      <InputField name="name" label="Fraktion/Name" {...form} />
      <InputField name="long_name" label="Langname" {...form} />
      {categories && (
        <Dropdown
          label="Kategorie"
          items={categories}
          name="category"
          {...form}
        />
      )}

      <InputField name="bls_id" label="BLS ID" {...form} />
      <InputField name="bls_description" label="BLS Beschreibung" {...form} />
      <InputField name="bls_order" label="BLS Ordnung" {...form} />
      <InputField name="ma48_id" label="MA48 ID" {...form} />
      <InputField name="ma48_description" label="MA48 Beschreibung" {...form} />
      <InputField name="comment" label="Kommentar" {...form} />
      <FormCheckbox as={Checkbox} name="has_alsag" label="alsag" {...form} />

      <FormCheckbox as={Checkbox} name="has_aviso" label="avisio" {...form} />
      <FormCheckbox
        as={Checkbox}
        name="has_cashoption"
        label="cashoption"
        {...form}
      />
      <FormCheckbox
        as={Checkbox}
        name="has_thirdparty"
        label="thridparty"
        {...form}
      />
    </div>
  );
};

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});

  throw errors;
};

const Page = () => {
  const [article, setArticle] = useState();
  const [categories, setCategories] = useState();
  const notifications = useNotification();

  const form = useFormState({
    values: {
      name: '',
      category: '',
      ma48_id: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.name) {
        errors = {
          ...errors,
          name: 'Name muss ausgefüllt werden',
        };
      }
      if (!values.category) {
        errors = {
          ...errors,
          category: 'Kategorie muss ausgewählt werden',
        };
      }
      if (!values.ma48_id) {
        errors = {
          ...errors,
          ma48_id: 'MA48 ID muss ausgefüllt werden',
        };
      }
      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);

      try {
        const {
          data: { data },
        } = await api({
          url: `/backend/api/articles${article?.id ? `/${article.id}` : ''}`,
          method: article?.id ? 'PUT' : 'POST',
          data: {
            ...values,
            customer_id: form.values?.customer_id?.value,
            category_id: form.values?.category?.value,
            group: values?.group?.value,
          },
        });

        setArticle(data);
        notifications.add({
          title: 'Artikel gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        if (!article?.id) {
          navigate(`?article=${data.id}`);
        }
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
        createLaravelFormError(error);
      }
    },
  });
  useEffect(() => {
    const getData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const articleId = params.get('article');
        if (!articleId) {
          const {
            data: { data: configData },
          } = await api.get(`/backend/api/configurations/`);

          setCategories(
            configData.map(itm => ({
              label: `${itm.internal_name} - ${itm.display_name}`,
              value: itm.id.toString(),
            })),
          );
          return;
        }

        const {
          data: {
            data,
            meta: { categories: categoriesData },
          },
        } = await api.get(`/backend/api/articles/${articleId}`);
        Object.entries(data).forEach(([key, val]) => {
          form.update(key, val);
        });

        form.update('category', {
          label: `${data.category.internal_name} - ${data.category.display_name}`,
          value: data.category.id,
        });
        setArticle(data);
        setCategories(
          categoriesData.map(itm => ({
            label: `${itm.internal_name} - ${itm.display_name}`,
            value: itm.id.toString(),
          })),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <Wrapper>
      <Layout
        breadcrumbs={[
          {
            to: '/artikel',
            title: 'Artikel',
          },
        ]}
        title={article?.name || 'Artikel anlegen'}
      >
        <ReakitForm {...form}>
          <StickyBar>
            <div className={styles.actions}>
              <FormSubmitButton {...form} className={styles.submit} as={Button}>
                Speichern
              </FormSubmitButton>
            </div>
          </StickyBar>
          {form.values && (
            <Container size="lg">
              <Form categories={categories} form={form} />
            </Container>
          )}
        </ReakitForm>
      </Layout>
    </Wrapper>
  );
};

export default Page;
